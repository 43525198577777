<script lang="ts">
export default {
    name: 'RegisterButton',
    props: {
        loginColor: { type: String, required: false },
    },
    emits : ['openConnexionModal'],
}
</script>

<template>
    <v-btn
        class="mx-2"
        variant="outlined"
        :color="loginColor || 'primary'"
        @click="$refs?.registerForm?.showModal()"
    >
        Créer un compte
    </v-btn>
    <ModalMiddle ref="registerForm" title-modal="Créer votre compte" :max-width="600" :hide-default-actions="true">
        <!-- activator-id="test" -->
        <template #content>
            <MenuAppbarRegisterForm @closeModal="$refs?.registerForm?.closeModal(); $emit('openConnexionModal')" />
        </template>
    </ModalMiddle>
</template>
