<script lang="ts">
export default {
    name: 'ConnexionButton',
    props: {
        loginColor: { type: String, required: false },
        titleModal: { type: String, required: true },
    },
    emits : ['launchRegister'],
    computed: {
        // hash() {
        //     return this.$route.hash
        // },
    },
    watch: {
        // hash() { // CANT WATCH ROUTE WHEN LOADING
        //     this.openConnexionModal()
        // },
    },
    methods: {
        openConnexionModal() {
            // console.log('test', this.$route)
            if (this.$route && (this.$route.hash.includes('connexion') || this.$route.path === '/connexion'))
                this.$refs?.loginForm?.showModal()
        },
        cleanRouteFromHash() {
            if (this.$route.hash)
                this.$router.push(this.$route.path.split('#')[0])
        },
    },
}
</script>

<template>
    <v-btn
        class="mx-2"
        variant="outlined"
        :color="loginColor || 'primary'"
        @click="$refs?.loginForm?.showModal()"
    >
        Se connecter
    </v-btn>
    <ModalMiddle
        ref="loginForm" :title-modal="titleModal" :max-width="600" :hide-default-actions="true"
        @is-mounted="console.log('mounted'); openConnexionModal()"
        @close-modal="cleanRouteFromHash"
    >
        <template #content>
            <MenuAppbarConnexionForm @launchRegister="$refs?.loginForm?.closeModal(); $emit('launchRegister')" />
        </template>
    </ModalMiddle>
</template>
