<script lang="ts">
import { useUserStore, useConfigStore  } from '~/store'

export default {
    name: 'MenuUserProfile',
    props: {
        loginColor: { type: String, required: false },
        appSlug : { type: String, required: false },
        titleConnexionModal: { type: String, required: false },
    },
    setup() {
        const access_token = useCookie<string>('access_token')
        const userStore = useUserStore()
        const configStore = useConfigStore()

        return {
            access_token,
            userStore,
            configStore,
        }
    },
    data: () => ({
        menu: false,
        message: false,
    }),
    mounted () {
        this.getAccessRequestNumber()
    },
    computed : {
        isSameDomainName () {
            if (process.browser) {
                return window.location.origin === this.configStore.currentConfig.admin_app_url
            }
            return
        },
        currentUser () {
            return this.$currentUser()
        },
        appInfo () {
            return this.configStore?.currentConfig?.app_info
        }
    },
    watch : {
        currentUser () {
                this.getAccessRequestNumber()
        },
        appInfo () {
            this.getAccessRequestNumber()
        }
    },
    methods: {
        getAccessRequestNumber() {
            if (this.currentUser?.role && this.currentUser.role !== 'user') {
                this.$api.setRequest({
                    url: `access-requests`,
                    query: this.appInfo?.slug ? { application : this.appInfo.slug} : {},
                    method: "GET",
                }).then((res: { meta: { total: any; }; }) => {
                    this.configStore.currentConfig.access_request_total = res.meta.total
                })
            } else {
                this.configStore.currentConfig.access_request_total = null
            }

        },
        accessRightAdminRedirect () {
            let url = `${this.configStore.currentConfig.admin_app_url}/demandes-acces`
            if (this.appInfo?.slug) 
                url += `?application=${this.appInfo.slug}`
            
            this.redirect(url)
        },
        redirect (url: string) {
            if (process.browser) {
                window.location.href = url
            }
        },
        async logout() {
            await this.$api.setRequest({
                url: 'logout',
                method: 'POST',
            })
            this.access_token = null
            await this.userStore.clearUser()
            this.$router.push('/')
        },
    },
}
</script>

<template>
    <client-only>
        <div v-if="$currentUser().k">
            <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
                <template #activator="{ props }">
                    <v-btn v-bind="props" class="mr-2">
                        {{ $currentUser().name }} 
                        <span v-if="$currentUser().role">
                            - {{ $translate(`utilisateurs.role.${$currentUser().role}`) }}
                        </span>
                    </v-btn>
                </template>

                <v-card min-width="300" variant="flat" color="light">
                    <v-list density="compact" selectable class="pa-0" bg-color="primary" @click.prevent="menu = false"> 
                        <template v-if="($currentUser().role === 'admin' || $currentUser().role === 'manager') && !isSameDomainName">
                            <v-list-item color="info" @click.prevent="accessRightAdminRedirect">
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-account-lock"></v-icon>
                                </template>
                                <v-list-item-title>Demandes d'accès</v-list-item-title>
                                <template #append v-if="configStore.currentConfig.access_request_total">
                                    <v-badge
                                        color="warning"
                                        :content="configStore.currentConfig.access_request_total"
                                        inline
                                    />
                                </template>
                            </v-list-item>
                            <v-list-item color="info" @click.prevent="redirect(configStore.currentConfig.admin_app_url)"> 
                                <template v-slot:prepend>
                                    <v-icon icon="mdi-cog"></v-icon>
                                </template>
                                <v-list-item-title>Administration</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                        </template>

                        <v-list-item color="info" @click.prevent="$router.push('/profil')">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-account"></v-icon>
                            </template>
                            <v-list-item-title>Mon compte</v-list-item-title>
                        </v-list-item>
                        <v-list-item color="info" @click.prevent="logout()">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-power-standby"></v-icon>
                            </template>
                            <v-list-item-title>Déconnexion</v-list-item-title>
                        </v-list-item>
                       
                    </v-list>
                </v-card>
            </v-menu>
        </div>
        <div v-else class="mx-5 d-flex">
            <template v-if="$slots.loginbuttons">
                <slot name="loginbuttons" />
            </template>
            <template v-else>
                <MenuAppbarConnexionButton ref="loginButton" :login-color="loginColor" :title-modal="titleConnexionModal" @launchRegister="$refs?.registerButton?.$refs?.registerForm?.showModal()" />
                <MenuAppbarRegisterButton ref="registerButton" :login-color="loginColor" @openConnexionModal="$refs?.loginButton?.$refs?.loginForm?.showModal()" />
            </template>
        </div>
    </client-only>
</template>
