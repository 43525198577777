<template>
  <v-app-bar
        color="primary"
        :elevation="elevation"
        prominent
      >
        <v-app-bar-nav-icon variant="text" v-if="!disableNavigation" @click.stop="$emit('showNavigation')"></v-app-bar-nav-icon>

        <v-toolbar-title>      
          <slot name="title" />
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <slot name="rightMenu" />
        <!-- <template v-if="$vuetify.display.mdAndUp">
        </template> -->
      </v-app-bar>
</template>
<script lang="ts">
export default {
    name : "AppBarMenu",
    emits: ['showNavigation'],
    props : {
      disableNavigation : { type : Boolean, required : false},
      elevation : { type : Number, required : false, default : 10 },
    }
}
</script>
