<script lang="ts">
import { useConfigStore } from '~/store'

useHead({
    titleTemplate: 'Pildom Portail - %s',
})

export default {
    name: 'DefaultLayout',
    setup() {
        const configStore = useConfigStore()

        return {
            configStore,
        }
    },
    computed: {
        currentPage() {
            return this.$currentPath(this.$route.matched[0].path).pop()
            // return this.$refs && this.$refs.MenuBreadcrumbs && this.$refs.MenuBreadcrumbs.breadcrumbItems.slice(-1)
        },
        loading() {
            return useConfigStore()?.globalLoading
        },
        maintenance() {
            return useConfigStore()?.maintenance
        },

    },
    mounted() {
        useConfigStore().setGlobalLoading(false)
        this.configStore.currentConfig.homepage_view = 'connexion'

        if (this.$config.public.app_slug) {
            this.$api.setRequest({
                url: `applications/${this.$config.public.app_slug}`,
                method: 'GET',
            }).then((res) => {
                this.configStore.currentConfig.app_info = res.data
            })
        }
    },
}
</script>

<template>
    <div>
        <header>
            <v-layout>
                <MenuGlobalLoader :loading="loading">
                    <template #loader>
                        <svg viewBox="25 25 50 50">
                            <circle
                                class="loader-path"
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                stroke="#636363"
                                stroke-width="2"
                            />
                        </svg>
                    </template>
                    <template #logo />
                </MenuGlobalLoader>

                <MenuMaintenanceMessage>
                    <template #logo />
                </MenuMaintenanceMessage>

                <client-only>
                    <v-main
                        v-if="!maintenance && !loading"
                        id="main-content"
                        class="bg-custom-light"
                    >
                        <MenuAppBar :disable-navigation="true" :elevation="0">
                            <template #title>
                                <v-row align="center">
                                    <v-col lg="3" class="text-center">
                                        LOGO
                                        <!-- <img
                                            class="w-auto"
                                            src="@nuxtcommon/assets/images/logo.png"
                                            alt="Pildom logo"
                                            height="60"
                                        > -->
                                    </v-col>
                                    <v-col>
                                        Programmation de travaux
                                    </v-col>
                                </v-row>
                            </template>
                            <template #rightMenu>
                                <MenuAppbarUserProfile>
                                    <template #loginbuttons>
                                        <v-btn
                                            class="mx-2"
                                            variant="outlined"
                                            @click="configStore.currentConfig.homepage_view = 'connexion'"
                                        >
                                            Connexion
                                        </v-btn>
                                        <v-btn
                                            class="mx-2"
                                            variant="outlined"
                                            @click="configStore.currentConfig.homepage_view = 'register'"
                                        >
                                            Créer un compte
                                        </v-btn>
                                    </template>
                                </MenuAppbarUserProfile>
                            </template>
                        </MenuAppBar>
                        <!-- TO DO : bg-primary lorsque l'utilisateur n'est pas connecté ou en attente d'acceptation  :class="$currentUser()" -->
                        <div id="page-content" class="pa-6" :class="$hasAccess($config.public.app_slug) ? 'bg-light' : 'bg-primary'">
                            <div
                                v-if="currentPage"
                                class="text-h4"
                            >
                                {{ $translate(currentPage.title) }}
                            </div>
                            <!-- <MenuGlobalAlert /> -->
                            <Breadcrumbs v-if="$route.path !== '/' || $hasAccess($config.public.app_slug)" />
                            <slot />
                            <Toaster />
                        </div>
                        <MenuFooter color="primary" />
                    </v-main>
                </client-only>
            </v-layout>
        </header>
    </div>
</template>
